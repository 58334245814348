import { QueryClient } from "react-query";

/**
 *
 * @author Abdul Rauf
 * @category Configurations
 * @created Jan 13, 2022
 * @link https://react-query.tanstack.com/reference/QueryClient
 * @description The QueryClient can be used to interact with a cache:
 */

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: false,
      staleTime: 5 * 60 * 1000,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});
