import { FC, ReactElement, ReactNode } from "react";
import { ButtonProps, Button, CircularProgress } from "@mui/material";

/**
 *
 * @author Muhammad Raheel
 * @category Components
 * @created Jan 13, 2022
 * @description MUI customized button element
 * @returns This function returns ReactElement
 */

export const MuiButton: FC<MuiButtonPropTypes> = ({
  label,
  startIcon,
  loading = false,
  size = "medium",
  type = "button",
  disabled = false,
  color = "primary",
  fullWidth = false,
  variant = "contained",
  ...restProps
}) => (
  <Button
    size={size}
    type={type}
    color={color}
    variant={variant}
    fullWidth={fullWidth}
    disabled={loading ? loading : disabled}
    startIcon={loading ? <CircularProgress color="inherit" size="1.5rem" /> : startIcon}
    {...restProps}
  >
    {label}
  </Button>
);

/**
 * @category Types
 * @author Muhammad Raheel
 * @created Jan 13, 2022
 * @link ButtonProps API Docs: https://mui.com/api/button/
 */

export type MuiButtonPropTypes = {
  loading?: boolean;
  label?: string | ReactElement | ReactNode;
} & ButtonProps;
