import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: localStorage.getItem("i18nextLng") || "en",
    detection: {
      order: ["querystring", "navigator", "path", "localStorage", "htmlTag", "cookie"],
      caches: ["localStorage", "cookie"],
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

/**
 *
 * @author Muhammad Raheel
 * @category Configurations
 * @created Jan 13, 2022
 * @description i18n config for using multi-languages in application
 * @link API Docs: https://react.i18next.com/
 */

export { i18n };
