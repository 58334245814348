import { FC } from "react";
import { Grid, Skeleton } from "@mui/material";

/**
 *
 * @author Abdul Rauf
 * @category Components
 * @created Jan 13, 2022
 * @description MUI customized Skeleton/loader for table
 * @returns This function returns ReactElement
 */

export const TableSkeleton: FC = () => {
  return (
    <Grid style={{ padding: 30, paddingTop: 15 }} container spacing={2}>
      <Grid item xs={3}>
        <Skeleton variant="text" height={40} />
      </Grid>

      <Grid item xs={3}>
        <Skeleton variant="text" height={40} />
      </Grid>

      <Grid item xs={3}>
        <Skeleton variant="text" height={40} />
      </Grid>

      <Grid item xs={3}>
        <Skeleton variant="text" height={40} />
      </Grid>

      <br />

      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" />
      </Grid>
    </Grid>
  );
};
