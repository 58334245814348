import { FC } from "react";
import Fab from "@mui/material/Fab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Toggle: FC<TogglePropTypes> = ({ open, handleDrawerOpen, handleDrawerClose }) => (
  <Fab
    size="small"
    color="primary"
    style={{ border: "3px solid #fff", position: "fixed", width: 35, height: 35 }}
    onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
  >
    {open ? <ArrowBackIcon fontSize="medium" /> : <ArrowForwardIcon fontSize="medium" />}
  </Fab>
);

type TogglePropTypes = {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
};

export default Toggle;
