import { FC, ReactNode } from "react";
import { Paper, PaperProps } from "@mui/material";

/**
 *
 * @author Muhammad Raheel
 * @category Components
 * @created Jan 13, 2022
 * @description MUI customized paper element
 * @returns This function returns ReactElement
 */

export const MuiPaper: FC<PaperPropTypes> = ({ children, ...restProps }) => <Paper {...restProps}>{children}</Paper>;

/**
 * @category Types
 * @author Muhammad Raheel
 * @created Jan 13, 2022
 * @link LinkProps API Docs: https://mui.com/api/paper/
 */
export type PaperPropTypes = { children: ReactNode } & PaperProps;
